.accordion__btn-label {
    color: $heading-dark;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;

    @include before-tablet {
        text-align: left;
    }
}
