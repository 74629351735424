.case-study-cta__image {
    position: relative;
    background-color: red;
    mask-image: url($theme_url + "images/svg/mask-angle-top-right-bg.svg?v=#{$buildNumber}"), url($theme_url + "images/png/mask-fill.png?v=#{$buildNumber}");
    mask-size: 100% auto, 100% calc(100% - #{vw-value(170px, 320)});
    mask-position: bottom right, top left;
    mask-repeat: no-repeat;
    @include keep-aspect-ratio(340px, 240px);

    @include tablet {
        align-self: stretch;
        flex: 0 0 auto;
        width: 40%;
        mask-image: url($theme_url + "images/svg/mask-angle-vertical-bottom-left-bg-alt.svg?v=#{$buildNumber}"), url($theme_url + "images/png/mask-fill.png?v=#{$buildNumber}");
        mask-size: auto 100%, calc(100% - #{vw-value(140px, 760)}) 100%;
        mask-position: center right, center left;
        @include update-aspect-ratio(360px, 200px);
    }

    @include small {
        width: 100%;
        height: auto;
        mask-image: url($theme_url + "images/svg/mask-angle-top-right-bg.svg?v=#{$buildNumber}"), url($theme_url + "images/png/mask-fill.png?v=#{$buildNumber}");
        mask-size: 100% auto, 100% calc(100% - 144px);
        mask-position: bottom right, top left;
        @include update-aspect-ratio(340px, 240px);
    }

    @include huge {
        mask-size: 100% auto, 100% calc(100% - 155px);
    }

    img {
        @extend %pos-100;
        object-fit: cover;
    }
}