$buildNumber: 100 !default;

/*Required Variables*/
$base-font-size: 16px;

/*Viewport Width Base*/
$viewport-base: 0;

/*Z-index Scale*/
$z-index-minus: -100;
$z-index-1: 100;
$z-index-2: 200;
$z-index-3: 300;
$z-index-4: 400;
$z-index-5: 500;
$z-index-6: 600;
$z-index-7: 700;
$z-index-8: 800;
$z-index-9: 900;
$z-index-10: 1000;

/*Standard Breakpoints*/
$mobile: 320px;
$phablet: 600px;
$tablet: 760px;
$small: 960px;
$medium: 1200px;
$huge: 1600px;
$max: 1920px;


/* Colour Variables */
$primary: #E21010;
$heading-dark: #111;
$heading: #222;
$heading-light: #333;
$text: #49494A;
$text-alt: #555;
$light-text: #777;
$bg-grey: #F3F3F3;
$bg-grey-border: #EEE;

$theme_url: '/wp-content/themes/chakray-2021/';