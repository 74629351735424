.heading__logo {
    display: block;
    position: relative;
    width: 100%;
    height: 50px;
    margin: 8px 0 7px;

    @include phablet {
        height: 62px;
    }

    @include medium {
        height: 68px;
    }

    @include huge {
        height: 73px;
    }

    img {
        width: 121px;
        height: 50px;

        @include phablet {
            width: 150px;
            height: 62px;
        }

        @include medium {
            width: 165px;
            height: 68px;
        }

        @include huge {
            width: 177px;
            height: 73px;
        }
    }
}