@keyframes slide-in-up {
    0% {
        opacity: 0;
        transform: translateY(15%);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes slide-in-up-tablet {
    0% {
        opacity: 0;
        transform: translateY(-20%);
    }

    100% {
        opacity: 1;
        transform: translateY(-50%);
    }
}

@keyframes slide-out-down {
    0% {
        opacity: 1;
        transform: none;
    }

    100% {
        opacity: 0;
        transform: translateY(15%);
    }
}

@keyframes slide-out-down-tablet {
    0% {
        opacity: 1;
        transform: translateY(-50%);
    }

    100% {
        opacity: 0;
        transform: translateY(-20%);
    }
}

@keyframes fade-in-up {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes fade-out-down {
    0% {
        opacity: 1;
        transform: none;
    }

    100% {
        opacity: 0;
        transform: translateY(100px);
    }
}
