.content-section {
    color: $text-alt;
    word-break: break-word;

    @for $i from 1 through 6 {
        h#{$i}:not(.heading) {
            @extend .heading;
            @extend .heading--#{$i};
            color: $heading-dark;
        }
    }

    .heading {
        color: $heading-dark;
    }

    .heading:not(.heading--underline):not(.heading--considerations) {
        margin-bottom: 7px;

        @include phablet {
            margin-bottom: 10px;
        }

        @include small {
            margin-bottom: 24px;
        }

        @include huge {
            margin-bottom: 21px;
        }
    }

    p:not(.subheading),
    li {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;

        @include medium {
            line-height: 26px;
            margin-bottom: 26px;
        }

        @include huge {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 28px;
        }

        strong {
            color: #222;
            font-weight: 600;
        }
    }

    li.vacancies-list__item {
        margin-bottom: 0;
    }

    h5:not(.heading) {
        font-size: 18px;
        line-height: 24px;

        @include medium {
            line-height: 26px;
        }

        @include huge {
            font-size: 20px;
            line-height: 28px;
        }
    }

    li:not(.vacancies-list__item) {
        margin-bottom: 5px;

        @include phablet {
            margin-bottom: 10px;
        }
    }

    ul:not(.list--tick-style) li:not(.vacancies-list__item) {
        padding-left: 16px;
        margin-bottom: 5px;

        @include phablet {
            padding-left: 20px;
            margin-bottom: 10px;
        }

        &::before {
            background: url($theme_url + 'images/svg/right-arrow.svg?color=E21010') no-repeat top left / contain;
            width: 5px;
            height: 9px;
            transform: none;
            top: 8px;
            left: 0;

            @include phablet {
                width: 7px;
                height: 12px;
                top: 4px;
            }

            @include medium {
                top: 6px;
            }

            @include huge {
                top: 9px;
            }
        }

        ul {
            padding-top: 4px;

            @include phablet {
                padding-top: 9px;
            }

            li:not(.vacancies-list__item) {
                &::before {
                    background: url($theme_url + 'images/svg/right-arrow.svg?color=777777') no-repeat center / contain;
                }
            }
        }
    }

    ol li {
        &::before {
            color: $primary;
        }
    }

    ol,
    ul {
        margin-bottom: 24px;

        @include medium {
            margin-bottom: 26px;
        }

        @include huge {
            margin-bottom: 28px;
        }
    }

    ul ul,
    ul ol,
    ol ul,
    ol ol {
        margin-bottom: 0;
    }

    p+ul:not(.list--tick-style),
    p+ol {
        margin-top: -18px;

        @include medium {
            margin-top: -21px;
        }

        @include huge {
            margin-top: -18px;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .responsive-table {
        width: 100%;
        max-width: 100%;
        overflow: auto;
    }

    /** WordPress Core **********************************/
    .alignnone {
        margin: 5px 20px 20px 0;
    }

    .aligncenter,
    div.aligncenter {
        display: block;
        margin: 5px auto 5px auto;
    }

    .alignright {
        float: right;
        margin: 5px 0 20px 20px;
    }

    .alignleft {
        float: left;
        margin: 5px 20px 20px 0;
    }

    a img.alignright {
        float: right;
        margin: 5px 0 20px 20px;
    }

    a img.alignnone {
        margin: 5px 20px 20px 0;
    }

    a img.alignleft {
        float: left;
        margin: 5px 20px 20px 0;
    }

    a img.aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .wp-caption {
        background: #FFF;
        border: 1px solid #F0F0F0;
        max-width: 96%;
        /* Image does not overflow the content area */
        padding: 5px 3px 10px;
        text-align: center;
    }

    .wp-caption.alignnone {
        margin: 5px 20px 20px 0;
    }

    .wp-caption.alignleft {
        margin: 5px 20px 20px 0;
    }

    .wp-caption.alignright {
        margin: 5px 0 20px 20px;
    }

    .wp-caption img {
        border: 0 none;
        height: auto;
        margin: 0;
        max-width: 98.5%;
        padding: 0;
        width: auto;
    }

    .wp-caption p.wp-caption-text {
        font-size: 11px;
        line-height: 17px;
        margin: 0;
        padding: 0 4px 5px;
    }

    /** END **********************************/

    img.aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    img {
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }

    blockquote {
        background: #F3F3F3;
        padding: 23px 30px 21px;
        color: #555;
        margin: 40px 0;
        border-left: 4px solid #E11F26;

        p:last-child {
            margin-bottom: 0;
        }
    }

    pre {
        width: 100%;
        overflow: auto;
    }

    table {
        border: 1px solid #EEE;
        border-collapse: collapse;
        border-spacing: 0;
        table-layout: fixed;
        width: auto;
        min-width: 100%;
        margin-bottom: 30px;

        td,
        th {
            font-size: 16px;
            line-height: 24px;
            border: 1px solid #EEE;
            color: #555;
            word-break: break-word;
            padding: 11px 20px 10px;
            text-align: left;

            @include medium {
                line-height: 26px;
            }

            @include huge {
                font-size: 18px;
                line-height: 28px;
            }

            strong {
                color: #222;
                font-weight: 600;
            }

            p,
            ol,
            ul {
                margin-bottom: 0;
            }

        }

        th {
            color: #222;
            font-weight: 600;
        }

        th:first-child,
        td:first-child {
            min-width: 140px;
        }
    }

    .accent--dark-bg {
        .heading {
            color: #fff;
        }

        p:not(.subheading),
        li {
            color: #fff;
        }
    }
}