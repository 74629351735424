.heading--sidebar {
    font-size: 19px;
    line-height: 26px;
    margin-bottom: 11px;

    @include small {
        font-size: 21px;
        line-height: 28px;
        margin-bottom: 9px;
    }
}
