.angled-card--no-image {
    padding: 25px 15px 30px;

    @include phablet {
        padding-top: 20px;
    }

    @include small {
        padding: 25px 15px;
    }

    @include huge {
        padding: 30px;
    }

    .angled-card__icon {
        margin: 0 auto 14px;

        @include huge {
            margin-bottom: 21px;
        }
    }

    .angled-card__action {
        padding: 0;
    }

    .angled-card__content {
        text-align: center;
        padding: 0;

        .heading--4 {

            @include medium {
                margin-bottom: 10px;
            }

            @include huge {
                margin-bottom: 12px;
            }
        }

        p {
            @include huge {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 24px;
            }
        }
    }
}