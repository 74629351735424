.advantages-card__content {
    border-top: 2px solid #EEE;
    padding: 20px 15px 1px;

    @include tablet {
        padding: 20px 20px 1px;
    }

    @include small {
        padding: 35px 32px 6px;
    }

    @include huge {
        padding: 40px 40px 1px;
    }
}