.angled-card__spacer {
    float: right;
    user-select: none;
    margin-top: #{vw-value(18px, 320)};
    width: #{vw-value(180px, 320)};
    @include keep-aspect-ratio(180, 95);
    shape-outside: polygon(0 0, 100% 0, 100% 100%, 0% 34%);


    @include breakpoint(370px) {
        width: #{vw-value(230px, 370)};
        margin-top: #{vw-value(20px, 370)};
    }

    @include breakpoint(400px) {
        width: 236px;
        margin-top: 27px;
    }

    @include phablet {
        width: #{vw-value(176px,600)};
        margin-top: #{vw-value(27px,600)};
    }

    @include tablet {
        width: #{vw-value(128px, 760)};
        margin-top: #{vw-value(30px, 760)};
        shape-outside: polygon(0 0, 100% 0, 100% 100%, 0% 42%);
    }

    @include medium {
        width: #{vw-value(148px,1200)};
        margin-top: #{vw-value(35px,1200)};
        shape-outside: polygon(0 0, 100% 0, 100% 100%, 0% 40%);
    }

    @include breakpoint(1330px) {
        width: #{vw-value(178px,1330)};
        margin-top: #{vw-value(30px,1330)};
    }

    @include huge {
        width: 148px;
        margin-top: 35px;
        shape-outside: polygon(0 0, 100% 0, 100% 100%, 0% 40%);
    }
}