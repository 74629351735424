.angled-card--strategic-alt {
    @extend .angled-card--strategic;

    .angled-card__content {
        padding-bottom: 10px;

        @include small {
            padding-top: 40px;
        }

        @include medium {
            padding-top: 0;
        }
    }
}