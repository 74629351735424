.angled-card--benefits-btn {
    @extend .angled-card--benefits;


    .angled-card__content {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        flex: 1 1 auto;

        padding-bottom: 25px;

        @include phablet {
            padding-bottom: 20px;
        }

        @include small {
            padding-bottom: 35px;
        }

        @include huge {
            padding-bottom: 50px;
        }

        p {
            margin-bottom: 18px;

            @include phablet {
                margin-bottom: 13px;
            }

            @include small {
                margin-bottom: 22px;
            }
        }

        .btn {
            margin: auto 0 0;
        }
    }
}