.agenda {
    display: flex;
    flex-flow: row;
    gap: 20px;
    align-items: flex-start;
    padding: 15px;

    @include tablet {
        align-items: center;
    }
}
