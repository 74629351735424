.angled-card__image {
    position: relative;
    width: 100%;
    @include keep-aspect-ratio(290, 175);
    margin-bottom: #{vw-value(-114px, 320)};
    mask-image: url($theme_url + "images/svg/mask-angle-top-right-bg.svg?v=#{$buildNumber}"), url($theme_url + "images/png/mask-fill.png?v=#{$buildNumber}");
    mask-size: 100% auto, 100% calc(100% - #{vw-value(140px, 320)});
    mask-position: bottom right, top left;
    mask-repeat: no-repeat;
    z-index: 0;

    @include breakpoint(370px) {
        margin-bottom: #{vw-value(-140px, 370)};
    }

    @include breakpoint(400px) {
        mask-size: 100% auto, 100% calc(100% - 170px);
        margin-bottom: -150px;
    }

    @include phablet {
        @include update-aspect-ratio(230, 153);
        margin-bottom: #{vw-value(-110px, 600)};
        mask-size: 100% auto, 100% calc(100% - #{vw-value(140px,600)});
    }

    @include tablet {
        @include update-aspect-ratio(230, 153);
        width: 100%;
        mask-size: 100% auto, 100% calc(100% - #{vw-value(110px, 760)});
        margin-bottom: #{vw-value(-93px, 760)};
    }

    @include small {
        @include update-aspect-ratio(265, 172);
        mask-size: 100% auto, 100% calc(100% - #{vw-value(140px, 960)});
    }

    @include medium {
        margin-bottom: -112px;
        mask-size: 100% auto, 100% calc(100% - #{vw-value(120px, 1200)});
    }

    @include breakpoint(1330px) {
        @include update-aspect-ratio(265, 160);
        margin-bottom: #{vw-value(-122px, 1330)};
        mask-size: 100% auto, 100% calc(100% - #{vw-value(140px, 1330)});
    }

    @include huge {
        height: 203px;
        margin-bottom: -130px;

        mask-size: 100% auto, 100% calc(100% - 150px);

        &::before {
            display: none;
        }
    }

    img {
        @include background-image;
    }
}