.heading--footer {
    @include roboto;
    color: #CCC;
    font-size: 17px;
    line-height: 30px;
    font-weight: 700;
    display: block;
    cursor: pointer;
    border-bottom: 1px solid rgba(#FFF, 0.1);
    text-transform: uppercase;
    padding: 12px 45px 11px 15px;
    position: relative;

    @include tablet {
        border: none;
        padding: 0;
        margin-bottom: 16px;
        cursor: initial;
    }

    @include huge {
        font-size: 21px;
        margin-bottom: 18px;
    }

    &::after {
        content: '';
        position: absolute;
        width: 13px;
        height: 8px;
        top: 23px;
        right: 16px;
        background: url($theme_url + 'images/svg/down-arrow.svg?color=cccccc') no-repeat top left / contain;

        @include tablet {
            display: none;
        }
    }
}
