.agenda-list__divider {
    border-radius: 3px;
    border-top: 1px solid rgba(238, 238, 238, 0.20);
    border-bottom: 1px solid rgba(238, 238, 238, 0.20);
    background: rgba(255, 255, 255, 0.10);
    padding: 0;
    margin: 0;

    &::before {
        display: none;
    }
}