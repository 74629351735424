.agenda-list__item--accent {
    padding: 0;
    margin: 0;
    background-color: #fff;
    border-left: 4px solid var(--red, #E21010);

    @include tablet {
        border-radius: 3px;
    }

    &::before {
        display: none;
    }

    &:first-of-type {
        border-top: none;
    }

    .agenda__text:not(.agenda__company) {
        >p, .heading {
            color: $heading-dark;
        }

        >p {
            opacity: 0.7;
        }

        .agenda__company {
            p {
                color: $primary;
                opacity: 1;
            }
        }
    }
}

.agenda-list__divider + .agenda-list__item {
    border-top: none;
}
