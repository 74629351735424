.heading--services-anka {
    font-size: 29px;
    line-height: 36px;
    margin: 30px 0 14px 0;

    @include tablet {
        font-size: 35px;
        line-height: 44px;
        margin: 50px 0 19px 0;
    }

    @include medium {
        font-size: 41px;
        line-height: 52px;
    }

    @include huge {
        font-size: 51px;
        line-height: 62px;
        margin: 100px 0 35px 0;
    }

}
