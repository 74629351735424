.heading--sidebar-form {
    font-size: 21px;
    line-height: 26px;

    @include tablet {
        font-size: 23px;
        line-height: 28px;
    }

    @include huge {
        font-size: 27px;
        line-height: 34px;
    }
}
