.accordion--active {
    @include before-tablet {
        .accordion__btn {
            background-color: $primary;
        }

        .accordion__btn-label {
            color: #fff;
        }

        .accordion__btn-icon {
            box-shadow: none;
            border: 2px solid #fff;

            svg {
                fill: #fff;
            }
        }


        .accordion__btn-icon--arrow {
            background-color: transparent;
            transform: rotate(180deg);
            border: none;

            svg {
                fill: #fff;
            }
        }

        //.accordion__body {
        //        display: block;
        //}
    }
}
