.heading__icon {
    @include icon-circle;
    width: 40px;
    height: 40px;
    flex: 0 0 auto;
    margin-bottom: 0;

    @include before-small {
        box-shadow: inset 0px 0px 0px 1px #FFF;
    }

    @include phablet {
        width: 50px;
        height: 50px;
    }

    @include small {
        width: 60px;
        height: 60px;
    }

    @include medium {
        margin-bottom: 0;
    }

    @include huge {
        width: 70px;
        height: 70px;
        margin-bottom: 0;
    }
}