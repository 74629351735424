.accent--dark-bg {
    background-color: black;

    .heading {
        color: #fff;
    }

    p:not(.subheading),
    li {
        color: #fff;
    }
}
