.angled-card--alt-angle {

    .angled-card__image {
        @include update-aspect-ratio(290, 137);
        margin-bottom: -72px;

        mask-image: url($theme_url + "images/svg/mask-angle-top-right-alt-bg.svg?v=#{$buildNumber}"), url($theme_url + "images/png/mask-fill.png?v=#{$buildNumber}");
        mask-repeat: no-repeat;
        mask-size: 150% auto, 100% calc(100% - #{vw-value(46px, 320)});
        mask-position: bottom right, top left;

        @include breakpoint(400px) {
            margin-bottom: -82px;
            mask-size: 150% auto, 100% calc(100% - #{vw-value(42px, 600)});
        }

        @include breakpoint(500px) {
            margin-bottom: -92px;
        }

        @include tablet {
            @include update-aspect-ratio(230, 108);
            margin-bottom: -64px;
            mask-size: 150% auto, 100% calc(100% - #{vw-value(36px, 760)});
        }

        @include medium {
            @include update-aspect-ratio(360, 149);
            margin-bottom: -83px;
            mask-size: 150% auto, 100% calc(100% - #{vw-value(50px, 1200)});
        }

        @include huge {
            height: 190px;
            margin-bottom: -94px;
            mask-size: 150% auto, 100% calc(100% - 70px);
        }
    }

    .angled-card__icon {
        margin-bottom: 17px;

        @include medium {
            margin-bottom: 27px;
        }

        @include huge {
            margin-bottom: 37px;
        }
    }

    .angled-card__content {
        @include medium {
            padding: 0 20px;
        }

        @include huge {
            padding: 0 40px;
        }
    }

    .angled-card__action {
        @include medium {
            padding: 0 20px 35px;
        }

        @include huge {
            padding: 0 40px 50px;
        }

        .btn {
            width: 100%;

            @include medium {
                width: auto;
            }
        }
    }

    .sub-heading {
        margin: 0 0 3px;

        @include medium {
            margin-bottom: 7px;
        }
    }

    .heading {
        font-size: 19px;
        line-height: 26px;
        margin-bottom: 11px;

        @include medium {
            font-size: 27px;
            line-height: 36px;
            margin-bottom: 16px;
        }

        @include huge {
            font-size: 29px;
            line-height: 38px;
            margin-bottom: 16px;
        }
    }

    p {
        color: $text-alt;
        line-height: 22px;
        margin-bottom: 8px;

        @include tablet {
            margin-bottom: 3px;
        }

        @include medium {
            line-height: 26px;
            margin-bottom: 27px;
        }

        @include huge {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 22px;
        }
    }
}
