.agenda__image {
    position: relative;
    width: 60px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: #FFF;
    flex: 0 0 auto;
    overflow: hidden;

    @include tablet {
        width: 80px;
    }

    @include huge {
        width: 100px;
    }

    img {
        @include pos(0, auto, auto, 0);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

}
