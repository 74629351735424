.heading--underline {
    position: relative;
    padding-bottom: 14px;
    margin-bottom: 15px;

    @include phablet {
        padding-bottom: 23px;
        margin-bottom: 25px;
    }

    @include small {
        padding-bottom: 28px;
        margin-bottom: 24px;
    }

    @include huge {
        padding-bottom: 29px;
        margin-bottom: 33px;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background: $primary;
        width: 40px;
        height: 2px;

        @include small {
            width: 50px;
        }
    }
}