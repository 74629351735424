.heading--speakers {
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    margin-bottom: 10px;
    color: #fff;

    @include tablet {
        font-size: 23px;
        line-height: 34px;
        margin-bottom: 15px;
    }

    @include huge {
        margin-bottom: 20px;
    }
}
