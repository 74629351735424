.heading--5 {
    font-size: 13px;
    line-height: 20px;

    @include medium {
        font-size: 17px;
        line-height: 26px;
    }

    @include huge {
        font-size: 19px;
        line-height: 28px;
    }
}
