.heading--footer-alt {
    @extend .heading--footer;
    cursor: initial;
    border: none;
    padding: 12px 0 11px;


    @include tablet {
        padding: 0;
    }

    &::after {
        display: none;
    }
}