.heading--4 {
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 10px;

    @include medium {
        font-size: 21px;
        line-height: 32px;
        margin-bottom: 6px;
    }

    @include huge {
        font-size: 25px;
        line-height: 34px;
    }

    small, sub {
        font-size: 0.69em;
        color: #555;
    }
}
