.heading--1 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 22px;

    @include phablet {
        font-size: 34px;
        line-height: 44px;
        margin-bottom: 30px;
    }

    @include small {
        font-size: 40px;
        line-height: 52px;
    }

    @include huge {
        font-size: 50px;
        line-height: 66px;
        margin-bottom: 45px;
    }
}
