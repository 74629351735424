//@include font-face("open-sans", "/fonts/open-sans/OpenSans-BoldItalic-webfont", $weight: 700, $style: italic);
//@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=b8222bd6-7e74-4175-b63d-974c3c33b7d8");

@include font-face('roboto-web', $theme_url + 'fonts/roboto/Roboto-Light', 400, normal);
@include font-face('roboto-web', $theme_url + 'fonts/roboto/Roboto-LightItalic', 400, italic);
@include font-face('roboto-web', $theme_url + 'fonts/roboto/Roboto-Regular', 450, normal);
@include font-face('roboto-web', $theme_url + 'fonts/roboto/Roboto-Medium', 500, normal);
@include font-face('roboto-web', $theme_url + 'fonts/roboto/Roboto-MediumItalic', 500, italic);
@include font-face('roboto-web', $theme_url + 'fonts/roboto/Roboto-Medium', 600, normal);
@include font-face('roboto-web', $theme_url + 'fonts/roboto/Roboto-MediumItalic', 600, italic);
@include font-face('roboto-web', $theme_url + 'fonts/roboto/Roboto-Bold', 700, normal);
@include font-face('roboto-web', $theme_url + 'fonts/roboto/Roboto-BoldItalic', 700, italic);
@include font-face('roboto-web', $theme_url + 'fonts/roboto/Roboto-Black', 900, normal);
@include font-face('roboto-web', $theme_url + 'fonts/roboto/Roboto-BlackItalic', 900, italic);

@mixin roboto {
    font-family: 'roboto-web', Arial, Helvetica, sans-serif;
}

@include font-face('metropolis-web', $theme_url + 'fonts/metropolis/Metropolis-Light', 300, normal);
@include font-face('metropolis-web', $theme_url + 'fonts/metropolis/Metropolis-LightItalic', 300, italic);
@include font-face('metropolis-web', $theme_url + 'fonts/metropolis/Metropolis-Regular', 400, normal);
@include font-face('metropolis-web', $theme_url + 'fonts/metropolis/Metropolis-RegularItalic', 400, italic);
@include font-face('metropolis-web', $theme_url + 'fonts/metropolis/Metropolis-Medium', 500, normal);
@include font-face('metropolis-web', $theme_url + 'fonts/metropolis/Metropolis-MediumItalic', 500, italic);
@include font-face('metropolis-web', $theme_url + 'fonts/metropolis/Metropolis-Bold', 700, normal);
@include font-face('metropolis-web', $theme_url + 'fonts/metropolis/Metropolis-BoldItalic', 700, italic);
@include font-face('metropolis-web', $theme_url + 'fonts/metropolis/Metropolis-Black', 900, normal);
@include font-face('metropolis-web', $theme_url + 'fonts/metropolis/Metropolis-BlackItalic', 900, italic);

@mixin metropolis {
    font-family: 'metropolis-web', Arial, Helvetica, sans-serif;
}
