.agenda__text {
    flex: 1 1 auto;

    .heading, p {
        margin-bottom: 0;
    }

    > p {
        color: rgba(#FFF, 0.7);
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        text-transform: uppercase;

        @include tablet {
            font-size: 16px;
            line-height: 26px;
        }
    }

    .heading {
        color: #FFF;
    }

    .heading--4 {
        @include huge {
            font-size: 26px;
            font-weight: 700;
            line-height: 34px;
        }
    }
}
