.heading--engagement {
    font-size: 21px;
    line-height: 24px;
    margin-bottom: 11px;

    @include huge {
        font-size: 31px;
        line-height: 38px;
        margin-bottom: 29px;
    }
}
