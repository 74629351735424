.agenda-list__item {
    padding: 0;
    margin: 0;
    border-top: 1px solid rgba(238, 238, 238, 0.20);

    &::before {
        display: none;
    }

    &:first-of-type {
        border-top: none;
    }
}

.agenda-list__divider + .agenda-list__item {
    border-top: none;
}
