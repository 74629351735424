.heading--services {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 14px;

    @include phablet {
        font-size: 21px;
        line-height: 28px;
        margin-bottom: 19px;
    }

    @include small {
        font-size: 23px;
        line-height: 30px;
        margin-bottom: 18px;
    }

    @include huge {
        font-size: 29px;
        line-height: 36px;
        margin-bottom: 27px;
    }
}
