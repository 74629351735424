.heading--3 {
    font-size: 21px;
    line-height: 24px;

    @include medium {
        font-size: 29px;
        line-height: 38px;
    }

    @include huge {
        font-size: 33px;
        line-height: 42px;
    }
}
