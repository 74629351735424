.angled-card--technologies {
    @include phablet {
        flex-flow: row;
    }

    @include small {
        flex-flow: column;
    }

    .angled-card__content {
        padding: 0 15px 15px;

        @include phablet {
            display: flex;
            gap: 25px;
            padding: 20px 20px 20px 0;
            margin-left: -50px;
        }

        @include small {
            padding: 0 20px 20px;
            margin-left: 0;
            display: block;
        }

        p {
            font-size: 16px;
            line-height: 24px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .heading {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 5px;

            @include huge {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }

    .angled-card__icon {
        margin-bottom: 17px;

        @include phablet {
            margin-bottom: 0;
            flex: 0 0 auto;
            width: 60px;
            height: 60px;
        }

        @include small {
            margin-bottom: 20px;
        }

        @include medium {
            width: 70px;
            height: 70px;
        }
    }

    .angled-card__image {
        @include update-aspect-ratio(290, 137);
        margin-bottom: -75px;

        mask-image: url($theme_url + "images/svg/mask-angle-top-right-alt-bg.svg?v=#{$buildNumber}"),
        url($theme_url + "images/png/mask-fill.png?v=#{$buildNumber}");
        mask-repeat: no-repeat;
        mask-size: 150% auto,
        100% calc(100% - #{vw-value(46px, 320)});
        mask-position: bottom right, top left;

        @include breakpoint(400px) {
            margin-bottom: -82px;
            mask-size: 150% auto,
            100% calc(100% - #{vw-value(46px, 320)});
        }

        @include breakpoint(500px) {
            margin-bottom: -92px;
            mask-size: 150% auto,
            100% calc(100% - #{vw-value(46px, 320)});
        }

        @include phablet {
            mask-image: url($theme_url + "images/svg/mask-angle-vertical-top-left-bg.svg?v=#{$buildNumber}");
            mask-size: 100% auto;
            mask-position: top right;
            margin: 0;
            max-width: 250px;
        }

        @include small {
            mask-image: url($theme_url + "images/svg/mask-angle-top-right-alt-bg.svg?v=#{$buildNumber}"),
            url($theme_url + "images/png/mask-fill.png?v=#{$buildNumber}");
            mask-repeat: no-repeat;
            mask-size: 300% auto,
            100% calc(100% - #{vw-value(46px, 320)});
            mask-position: bottom right, top left;
            @include update-aspect-ratio(370, 163);
            margin-bottom: -70px;
            max-width: none;
        }

        @include medium {
            @include update-aspect-ratio(370, 163);
        }

        @include breakpoint(1330px) {
            @include update-aspect-ratio(370, 163);
        }


        @include huge {
            height: 163px;
            @include update-aspect-ratio(370, 163);
        }
    }
}