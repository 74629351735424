.agenda__company {
    display: flex;
    flex-flow: column;
    gap: 8px;

    @include tablet {
        flex-flow: row;
        justify-content: space-between;
    }

     p {
         color: #FF5656;
         font-size: 14px;
         font-weight: 400;
         line-height: 24px;
         text-transform: none;

         @include tablet {
             font-size: 16px;
             line-height: 26px;
         }
     }
}
