.angled-card--flat {
    .angled-card__image {
        mask: none;
        @include update-aspect-ratio(290, 110);
        margin-bottom: -35px;

        @include phablet {
            @include update-aspect-ratio(350, 110);
        }

        @include small {
            height: auto;
            @include update-aspect-ratio(285, 100);
            margin-bottom: -46px;

            &::before {
                display: block;
            }
        }

        @include huge {
            @include update-aspect-ratio(335, 140);
        }
    }

    .angled-card__content {
        padding: 0 15px;
        text-align: center;

        @include phablet {
            padding: 0 20px;
        }

        @include huge {
            padding: 0 30px;
        }

        .heading {
            font-size: 19px;
            line-height: 36px;
            margin-bottom: 14px;

            @include medium {
                font-size: 25px;
                line-height: 38px;
                margin-bottom: 20px;
            }
        }
    }

    .angled-card__icon {
        margin: 0 auto 9px;

        @include small {
            margin-bottom: 19px;
        }
    }

    .angled-card__action {
        padding: 0 15px 25px;

        @include phablet {
            padding: 0 20px 25px;
        }

        @include small {
            padding-bottom: 30px;
        }

        @include huge {
            padding: 0 30px 30px;
        }
    }
}
