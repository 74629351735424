.heading--icon {
    display: flex;
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 15px;

    @include small {
        font-size: 27px;
        line-height: 32px;
        margin-bottom: 20px;
    }

    @include huge {
        font-size: 29px;
        line-height: 36px;
        margin-bottom: 23px;
    }

    .heading__text {
        margin: auto 0 auto 11px;

        @include tablet {
            margin-left: 15px;
        }

        @include small {
            margin-left: 20px;
        }
    }
}
