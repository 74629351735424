.angled-card--benefits {
    @extend .angled-card--alt-angle;

    .angled-card__image {
        @include before-phablet {
            @include breakpoint(400px) {
                mask-size: 150% auto, 100% calc(100% - #{vw-value(42px, 320)});
            }
        }
    }

    .angled-card__icon {
        margin-bottom: 13px;

        @include small {
            width: 93px;
            height: 93px;
            margin-bottom: 21px;
        }

        @include huge {
            margin-bottom: 31px;
        }
    }

    .angled-card__content {
        padding-bottom: 10px;

        @include phablet {
            padding-bottom: 20px;
        }

        @include small {
            padding-bottom: 5px;
        }

        @include huge {
            padding-bottom: 15px;
        }
    }
}