.accordion__btn-icon--arrow {
    background-color: transparent;
    width: 13px;
    height: 6px;
    margin-left: auto;
    border:none;

    svg {
        fill: #555;
    }
}
