.angled-card__content {
    padding: 0 15px;
    position: relative;
    z-index: 1;

    @include huge {
        padding: 0 30px;
    }

    p {
        font-size: 14px;
        line-height: 20px;

        @include medium {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 13px;
        }
    }
}