.advantages-card__top {
    background: #FFF;
    padding: 21px 15px 18px;

    @include tablet {
        padding: 22px 20px 17px;
    }

    @include small {
        padding: 35px 30px 30px;
    }

    @include huge {
        padding: 40px 40px 35px;
    }
}