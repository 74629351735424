.accordion__btn-icon {
    flex: 0 0 auto;
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 10px;
    background: $primary;
    border: 2px solid $primary;
    box-shadow: inset 0px 0px 0px 2px #FFF;

    svg {
        @extend %pos-100;
        fill: #fff;
    }
}
