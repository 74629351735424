.angled-card__image--small {
    @include keep-aspect-ratio(290, 165);
    margin-bottom: #{vw-value(-115px, 320)};

    @include breakpoint(400px) {
        margin-bottom: -150px;
    }

    @include phablet {
        @include keep-aspect-ratio(230, 137);
        margin-bottom: #{vw-value(-117px, 600)};
    }

    @include tablet {
        margin-bottom: #{vw-value(-87px, 760)};
    }

    @include small {
        @include keep-aspect-ratio(265, 150);
        margin-bottom: #{vw-value(-110px, 960)};
    }

    @include breakpoint(1100px) {
        margin-bottom: #{vw-value(-140px, 1100)};
    }

    @include medium {
        margin-bottom: #{vw-value(-110px, 1200)};
    }

    @include huge {
        height: auto;
        @include keep-aspect-ratio(335, 177);
        margin-bottom: -135px;
    }
}