@mixin pos($top: auto, $right: auto, $bottom: auto, $left: auto, $position: absolute) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    position: $position;
}

@mixin relative-reset {
    @include pos(auto, auto, auto, auto, relative);
}