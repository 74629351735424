.agenda--info {
    align-items: center;

    .agenda__text {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;

        @include before-phablet {
            flex-flow: column;
            gap: 5px;
        }

        .heading--5 {
            font-size: 14px;

            @include huge {
                font-size: 20px;
            }
        }
    }
}