body {
    @include roboto;
    color: $text;
}

.editable-content {
    h1 {
        @extend .heading;
        @extend .heading--1;
    }

    h2 {
        @extend .heading;
        @extend .heading--2;
    }

    h3 {
        @extend .heading;
        @extend .heading--3;
    }

    h4 {
        @extend .heading;
        @extend .heading--4;
    }

    h5 {
        @extend .heading;
        @extend .heading--5;
    }
}

@mixin small-text-style {
    color: inherit;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 1em;

    @include small {
        line-height: 26px;
    }

    strong, b {
        font-weight: 600;
    }

    a {
        font-weight: 600;
    }
}

@mixin main-text-style {
    @include small-text-style;

    @include tablet {
        line-height: 26px;
    }

    @include medium {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 26px;
    }

    @include huge {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 32px;
    }
}

@mixin large-text-style {
    color: inherit;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 26px;

    @include medium {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 32px;
    }

    @include huge {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 40px;
    }
}

p {
    @include main-text-style;
}


a {
    color: inherit;
}

strong {
    font-weight: 700;
}

em {
    font-style: italic;
}

li {
    @include main-text-style;
}

ol, ul {
    list-style: none;
    padding: 0;
    margin: 0 0 27px;

    li {
        margin: 0 0 5px;
        padding: 0 0 0 21px;
        position: relative;

        @include huge {
            margin: 0 0 10px;
            padding: 0 0 0 35px;
        }

        ul, ol {
            padding-top: 10px;
            margin-bottom: 10px;
        }

        strong {
            font-weight: 600;
        }
    }
}

ol {
    counter-reset: items;
    padding: 0 0 0 40px;

    li {
        padding: 0;

        &::before {
            content: counters(items, ".") ". ";
            white-space: pre;
            counter-increment: items;
            position: absolute;
            font-weight: 700;
            top: 0px;
            right: calc(100% + 10px);
            display: block;
        }
    }
}

ul {
    li {
        &::before {
            content: '';
            width: 5px;
            height: 5px;
            top: 8px;
            left: 3px;
            position: absolute;
            background: red;
            transform: rotate(-45deg);

            @include huge {
                width: 8px;
                height: 8px;
            }
        }
    }
}

@mixin underline-animation($color) {
    text-decoration: none;
    background-image: linear-gradient(
                    transparent 1px,
                    $color 1px,
                    $color 2px,
                    transparent 3px
    ),
    linear-gradient(
                    transparent 1px,
                    transparent 1px,
                    transparent 3px,
                    transparent 3px
    );
    background-size: 0 3px, 100% 3px;
    background-position: 0 bottom, 0 bottom;
    transition: background-size 0.3s ease-in-out;
    background-repeat: no-repeat;
    padding-bottom: 0;
    border-bottom: 3px solid transparent;

    &:hover {
        background-size: 100% 3px;
    }
}
