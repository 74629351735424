.heading--circle-line {
    position: relative;
    overflow: hidden;
    align-items: center;
    width: 100%;

    span {
        position: relative;

        &::after {
            content: '';
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            border: 1px solid $primary;
            position: absolute;
            bottom: 5px;
            margin-left: 10px;

            @include small {
                bottom: 9px;
            }
        }
    }

    &::after {
        content: '';
        display: inline-block;
        height: 1px;
        width: 100%;
        background: $primary;
        position: absolute;
        bottom: 12px;
        margin-left: 15px;

        @include small {
            bottom: 13px;
        }
    }
}
