.angled-card--strategic {

    .angled-card__content {
        padding-right: 30px;
        padding-bottom: 30px;

        .heading {
            line-height: 24px;
            margin-bottom: 11px;

            @include small {
                line-height: 30px;
            }

            @include huge {
                line-height: 36px;
                margin-bottom: 14px;
            }
        }

        .heading--circle-line {

            @include medium {
                padding-right: 10px;
            }

            &::after {
                bottom: 11px;

                @include small {
                    bottom: 14px;
                }

                @include medium {
                    bottom: 12px;
                }

                @include huge {
                    bottom: 16px;
                }
            }

            span {
                &::after {
                    @include small {
                        bottom: 5px;
                    }

                    @include huge {
                        bottom: 8px;
                    }
                }
            }
        }

        p {
            margin-bottom: 9px;

            @include tablet {
                margin-bottom: 3px;
            }

            @include medium {
                margin-bottom: 7px;
            }

            @include huge {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 14px;
            }
        }
    }

    .angled-card__icon {
        margin-bottom: #{vw-value(32px, 320)};

        @include breakpoint(400px) {
            margin-bottom: 50px;
        }

        @include phablet {
            margin-bottom: 14px;
        }

        @include small {
            margin-bottom: 21px;
        }

        @include breakpoint(1100px) {
            margin-bottom: 30px;
        }
    }

    .angled-card__spacer {
        width: 68%;

        @include tablet {
            width: 58%;
        }

        @include medium {
            width: 60%;
        }
    }
}
