%pos-100 {
    @include pos(0, auto, auto, 0);
    width: 100%;
    height: 100%;
}

%pos-center {
    @include pos(50%, auto, auto, 50%);
}

@mixin icon-circle {
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: $primary;
    margin-bottom: 13px;
    border: 2px solid $primary;
    box-shadow: inset 0px 0px 0px 2px #FFF;
    position: relative;

    @include medium {
        width: 84px;
        height: 84px;
        margin-bottom: 21px;
        border: 3px solid $primary;
        box-shadow: inset 0px 0px 0px 3px #FFF;
    }

    @include breakpoint(1330px) {
        width: 100px;
        height: 100px;
    }

    svg {
        @include pos-100;
        fill: #FFF;
    }
}