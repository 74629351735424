.heading--2 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 5px;

    @include tablet {
        font-size: 34px;
        line-height: 44px;
        margin-bottom: 7px;
    }

    @include medium {
        font-size: 40px;
        line-height: 52px;
    }

    @include huge {
        font-size: 49px;
        line-height: 62px;
        margin-bottom: 3px;
    }
}
