.chapter-heading__img {
    @extend %pos-100;

    &::after {
        content: '';
        @extend %pos-100;
        background-color: #222;
        opacity: 0.6;
    }

    img {
        @extend %pos-100;
        object-fit: cover;
    }
}